(function ($) {
  $(window).load(function () {

    // toggle homepage banner on mobile
    if (device.mobile()) {
      $('.eyecatcher .vimeo-video').remove();
    } else {
      $('.eyecatcher .image-collection').remove();
    }

    setTimeout(() => {
      if ($(window).width() < 768) {
        return;
      }

      if (-1 === document.location.search.indexOf('form[sent]') || -1 === document.location.hash.indexOf('#form')) {
        return;
      }

      var $stickyHeader = $('header.header');
      var $message = $('.form-success-message');

      if (!$stickyHeader.length || !$message.length) {
        return;
      }

      var currentScrollTop = $('html').scrollTop();
      var newScrollTop = $message.offset().top - $stickyHeader.outerHeight() - 50;
      if (newScrollTop >= currentScrollTop) {
        return;
      }

      $("html, body").animate({scrollTop: newScrollTop});
    }, 500);

    $('.owl-carousel:not(.slider)').owlCarousel({
      // slider settings
      autoplay: true,
      autoplayHoverPause: true,
      loop: true,
      items: 1,

      // slide speed
      autoplayTimeout: 7000,
      smartSpeed: 500,

      // animation
      animateIn: 'fadeIn',
      animateOut: 'fadeOut',

      // navigation
      nav: false,
      navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],

      dots: true,

      // drag options
      mouseDrag: false
    })

    $(".slider.circle-slides-slider").owlCarousel({
      // slider settings
      autoplay: true,
      autoplayHoverPause: true,
      loop: true,
      items: 1,
      margin: 30,

      // slide speed
      autoplayTimeout: 4000,
      smartSpeed: 1000,

      // navigation
      nav: true,
      navContainer: false,
      navText: [
        // '<i class=\'fas fa-chevron-left\'></i>', // left
        // '<i class=\'fas fa-chevron-right\'></i>' // right
        '<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 47" width="29" height="47"><style>.a{fill:#ef5b97}</style><path class="a" d="m1.5 19.7l18.1-18.1c2.1-2.1 5.6-2.1 7.7 0 2.2 2.1 2.2 5.6 0 7.7l-14.2 14.2 14.2 14.2c2.2 2.2 2.2 5.6 0 7.8-2.1 2.1-5.6 2.1-7.7 0l-18.1-18.1c-2.1-2.1-2.1-5.6 0-7.7z"/></svg>', // left
        '<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 47" width="29" height="47"><style>.a{fill:#ef5b97}</style><path class="a" d="m27.5 27.4l-18.1 18.1c-2.1 2.1-5.6 2.1-7.7 0-2.2-2.2-2.2-5.6 0-7.8l14.2-14.2-14.2-14.2c-2.2-2.1-2.2-5.6 0-7.7 2.1-2.1 5.6-2.1 7.7 0l18.1 18.1c2.1 2.1 2.1 5.6 0 7.7z"/></svg>' // right
      ],

      dots: true,

      // drag options
      mouseDrag: true,

      // responsive
      responsive: {
        // breakpoint from 0 up
        0: {
          items: 1,
          margin: 15
        },

        // breakpoint from 576 up
        576: {
          items: 1,
          margin: 20,
        },

        // breakpoint from 768 up
        768: {
          items: 2
        },

        // breakpoint from 992 up
        992: {
          items: 3,
          margin: 20,
        },

        // breakpoint from 1200 up
        1200: {
          items: 4,
          margin: 30,
        }
      }
    });

    $(".slider").owlCarousel({
      // slider settings
      autoplay: true,
      autoplayHoverPause: true,
      loop: true,
      items: 1,
      margin: 40,

      // slide speed
      autoplayTimeout: 4000,
      smartSpeed: 1000,

      // navigation
      nav: true,
      navContainer: false,
      navText: [
        // '<i class=\'fas fa-chevron-left\'></i>', // left
        // '<i class=\'fas fa-chevron-right\'></i>' // right
        '<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 47" width="29" height="47"><style>.a{fill:#ef5b97}</style><path class="a" d="m1.5 19.7l18.1-18.1c2.1-2.1 5.6-2.1 7.7 0 2.2 2.1 2.2 5.6 0 7.7l-14.2 14.2 14.2 14.2c2.2 2.2 2.2 5.6 0 7.8-2.1 2.1-5.6 2.1-7.7 0l-18.1-18.1c-2.1-2.1-2.1-5.6 0-7.7z"/></svg>', // left
        '<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 47" width="29" height="47"><style>.a{fill:#ef5b97}</style><path class="a" d="m27.5 27.4l-18.1 18.1c-2.1 2.1-5.6 2.1-7.7 0-2.2-2.2-2.2-5.6 0-7.8l14.2-14.2-14.2-14.2c-2.2-2.1-2.2-5.6 0-7.7 2.1-2.1 5.6-2.1 7.7 0l18.1 18.1c2.1 2.1 2.1 5.6 0 7.7z"/></svg>' // right
      ],

      dots: true,

      // drag options
      mouseDrag: true,

      // responsive
      responsive: {
        // breakpoint from 0 up
        0: {
          items: 1,
          margin: 15
        },

        // breakpoint from 576 up
        576: {
          items: 2,
          margin: 20,
        },

        // breakpoint from 768 up
        768: {
          items: 2
        },

        // breakpoint from 992 up
        992: {
          items: 2,
          margin: 20,
        },

        // breakpoint from 1200 up
        1200: {
          items: 3,
          margin: 40,
        }
      }
    });
  });

  $(document).ready(function () {

    // sticky
    require('../../vendor/w3media/framework/assets/js/V2/body-sticky-class');

    // fancybox
    $('a[data-fancybox]').fancybox({
      buttons: [
        //"zoom",
        //"share",
        //"slideShow",
        //"fullScreen",
        //"download",
        "thumbs",
        "close"
      ]
    });

    // navbar-toggler
    $(document).on('click', '.navbar-toggler', function () {
      $('body').toggleClass('navbar-collapse-active');
    });

    // js-3w-scroll-to-content
    $('.js-3w-scroll-to-content').on('click', function (event) {
      event.preventDefault();
      var identifier = $(this).attr('data-target');
      var $element = $(identifier);

      if ($element) {
        $('html, body').animate({scrollTop: $element.offset().top}, 500);
      }
    });

    $('.search-icon').click(function() {
      $(this).parent().find('.form-control').toggleClass('show');
      $(this).toggleClass('hide');
    });

    var windowWidth = $(window).width();
    if (windowWidth > 1200) {
      $(window).scroll(function(){
        if ($(window).scrollTop() > 0) {
          $('body').addClass('sticky');
          $('.header').addClass('sticky');
        }
        else {
          $('body').removeClass('sticky');
          $('.header').removeClass('sticky');
        }
      });
    }

    $('a[data-fancybox]').fancybox({
      buttons: [
          //"zoom",
          //"share",
          //"slideShow",
          //"fullScreen",
          //"download",
          "thumbs",
          "close"
      ]
    });  

  });

})(jQuery);
